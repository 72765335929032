<template>
  <div class="">
    <!-- <van-nav-bar title="画报分享">
      <template #left>
        <van-icon name="cross" size="18" />
      </template>
    </van-nav-bar> -->
    <vue-canvas-poster
      :widthPixels="0"
      :painting="painting"
      @success="success"
      @fail="fail"
    ></vue-canvas-poster>
    <div class="share_img">
      <img :src="posterImg" />
    </div>
    <!-- <div class="pre_img">
      <div @click="downloadIamge">保存图片</div>
    </div> -->
    <!-- <a :href="posterImg" download="普利教育分享二维码">普利教育分享二维码</a> -->
  </div>
</template>

<script>
import { VueCanvasPoster } from "vue-canvas-poster";
import { queryShopGoodsDetail } from "@/api/shop";
export default {
  data () {
    return {
      shareImgWidth: 0, //屏幕手机宽度
      painting: {},
      posterImg: "",
      id: 0,
      detail: {},
    };
  },
  mounted () {
    this.id = this.$route.query.goodsId;
    this.getQueryShopGoodsDetail();
  },
  methods: {
    downloadIamge () {
      // let image = new Image();
      // image.setAttribute("crossOrigin", "anonymous");
      // //  image.onload = function() {
      // let timestamp = Date.parse(new Date());// 时间戳
      // let url = this.posterImg; //得到图片的base64编码数据
      // let a = document.createElement("a"); // 生成一个a元素
      // let event = new MouseEvent("click"); // 创建一个单击事件
      // a.download = "普利教育分享二维码_" + timestamp + '.png'; // 设置图片名称
      // a.href = url; // 将生成的URL设置为a.href属性
      // a.click();
      // let aLink = document.createElement('a')
      // let blob = this.base64ToBlob(this.posterImg)
      return
      let utl = "https://api.pulijiaoyu.org.cn/studentface/f629cda7d48a4e1fb5f110c7eb85df98imgSrc.png"
      if (!window.plus) return;
      plus.gallery.save(utl, function () {
        plus.nativeUI.alert("保存图片到相册成功");
      }, function () {
        plus.nativeUI.alert("保存失败");
      });
      // this.imageDown(blob)
      // let evt = document.createEvent('HTMLEvents')
      // evt.initEvent('click', true, true)
      // aLink.download = '普利教育分享二维码.jpg'
      // aLink.href = URL.createObjectURL(blob)
      // aLink.click()
      // this.$message({message:'亲，正在进行下载保存',type:'warning'})
      // a.dispatchEvent(event); // 触发a的单击事件
      //  };
      //  image.src = "www.baidu.com";
    },
    imageDown (base64, success, error) {
      let that = this
      var bitmap = new window.plus.nativeObj.Bitmap('image')
      bitmap.loadBase64Data(base64, function (i) {
        that.bigmapToSave(bitmap, success, error)
      }, function (err) {
        window.plus.nativeUI(err.message)
      })
    },
    bigmapToSave (bitmap, success, error) {
      let that = this
      var name = that.randomString(10)
      bitmap.save(`_doc/${name}.jpg`, { overwrite: false }, function (event) {
        console.log(event.target)
        that.imageSaveByGallery(event.target, success, error)
      }, function (err) {
        window.plus.nativeUI(err.message)
      })
    },
    imageSaveByGallery (url, success, error) {
      window.plus.gallery.save(url, function (event) {
        const tarbitmap = window.plus.nativeObj.Bitmap.getBitmapById('image')
        tarbitmap.clear()
        success()
      }, function (err) {
        window.plus.nativeUI(err.message)
        error()
      })
    },
    randomString (e) {
      e = e || 32
      var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      var a = t.length
      var n = ''
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n
    },
    // 图片转base64
    base64ToBlob (code) {
      let parts = code.split(';base64,')
      let contentType = parts[0].split(':')[1]
      let raw = window.atob(parts[1])
      let rawLength = raw.length
      let uInt8Array = new Uint8Array(rawLength)
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      return new Blob([uInt8Array], { type: contentType })
    },
    // 获取商品详情
    async getQueryShopGoodsDetail () {
      const res = await queryShopGoodsDetail(this.id);
      this.detail = res.response;
      this.init();
    },
    init () {
      this.shareImgWidth = document.documentElement.clientWidth;
      this.painting = {
        width: `${this.shareImgWidth * 0.92}px`,
        height: `${this.shareImgWidth * 0.92 * 1.45}px`,
        borderRadius: "10px",
        background: "#ffffff",
        views: [
          // 商品图片
          {
            type: "image",
            // url: "https://img01.yzcdn.cn/vant/logo.png",
            url: this.detail.CoverImg,
            css: {
              width: `${this.shareImgWidth * 0.92}px`,
              height: `${this.shareImgWidth * 0.92}px`,
              mode: "scaleToFill",
              borderRadius: "10px 10px 0 0",
              top: `0px`,
              left: `0px`,
            },
          },
          // {
          //   type: "qrcode",
          //   content: "19263",  //可以为内容
          //   css: {
          //     top: `${this.shareImgWidth / 1}px`,
          //     left: `${this.shareImgWidth / 2.5}px`,
          //     color: "#333",
          //     width: `${this.shareImgWidth / 5}px`,
          //     height: `${this.shareImgWidth / 5}px`,
          //   },
          // },
          // 商品信息
          {
            type: "text",
            text: this.detail.GoodsName,
            css: [
              {
                top: `${this.shareImgWidth * 0.96}px`,
                left: `${this.shareImgWidth * 0.04}px`,
                fontSize: `${this.shareImgWidth * 0.038}px`,
                color: "#333",
                fontFamily: "PingFang SC",
                fontWeight: "bold",
                width: `${this.shareImgWidth * 0.53}px`,
                maxLines: 2,
                lineHeight: `${this.shareImgWidth * 0.059}px`,
              },
            ],
          },
          // 价格
          {
            type: "text",
            text: this.detail.DefaultPrice + "",
            css: [
              {
                top: `${this.shareImgWidth * 0.984}px`,
                right: `${this.shareImgWidth * 0.07}px`,
                color: "#FE4A4A",
                fontSize: `${this.shareImgWidth * 0.054}px`,
                fontFamily: "PingFang SC",
                fontWeight: "bolder",
              },
            ],
          },
          // 人民币符号
          {
            type: "text",
            text: "￥",
            css: [
              {
                top: `${this.shareImgWidth * 1.002}px`,
                right: `${this.shareImgWidth * 0.215}px`,
                color: "#FE4A4A",
                fontSize: `${this.shareImgWidth * 0.032}px`,
                fontFamily: "PingFang SC",
                fontWeight: "400",
              },
            ],
          },
          // 竖线
          {
            type: "rect",
            css: [
              {
                top: `${this.shareImgWidth * 0.973}px`,
                right: `${this.shareImgWidth * 0.264}px`,
                color: "#EFEFEF",
                width: "1px",
                height: `${this.shareImgWidth * 0.086}px`,
              },
            ],
          },
          // 二维码 暂时放的百度地址
          {
            type: "qrcode",
            content: "https://www.baidu.com/",
            css: {
              top: `${this.shareImgWidth * 1.112}px`,
              left: `${this.shareImgWidth * 0.04}px`,
              color: "#000",
              width: `${this.shareImgWidth * 0.15}px`,
              height: `${this.shareImgWidth * 0.15}px`,
            },
          },
          // logo
          {
            type: "image",
            url: require("../../../assets/ShopImg/logo111.png"),
            css: {
              width: `${this.shareImgWidth * 0.112}px`,
              height: `${this.shareImgWidth * 0.112}px`,
              mode: "scaleToFill",
              borderRadius: "10px",
              top: `${this.shareImgWidth * 1.13}px`,
              right: `${this.shareImgWidth * 0.08}px`,
            },
          },
          {
            type: "text",
            text: "①长按图片保存",
            css: [
              {
                top: `${this.shareImgWidth * 1.128}px`,
                left: `${this.shareImgWidth * 0.23}px`,
                color: "#BEBDBD",
                fontSize: `${this.shareImgWidth * 0.035}px`,
                fontFamily: "PingFang SC",
                fontWeight: "400",
              },
            ],
          },
          {
            type: "text",
            text: "②打开App立即看见",
            css: [
              {
                top: `${this.shareImgWidth * 1.2}px`,
                left: `${this.shareImgWidth * 0.23}px`,
                color: "#BEBDBD",
                fontSize: `${this.shareImgWidth * 0.035}px`,
                fontFamily: "PingFang SC",
                fontWeight: "400",
              },
            ],
          },
        ],
      };
    },
    success (src) {
      // console.log(src);
      this.posterImg = src;
    },
    fail (err) {
      console.log("fail", err);
    },
  },
  components: {
    VueCanvasPoster,
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-ellipsis {
  color: #333333;
  font-size: 1rem;
  font-weight: 700;
}
/deep/ .van-nav-bar .van-icon {
  color: #333333;
}
//导航栏下边框
/deep/ .van-hairline--bottom::after {
  border: 0;
}
.share_img {
  width: 100%;
  box-sizing: border-box;
  padding-top: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pre_img {
  width: 100%;
  margin: 2.625rem 0;
  display: flex;
  justify-content: center;
  div {
    width: 17.5rem;
    height: 2.625rem;
    border-radius: 1.875rem;
    text-align: center;
    line-height: 2.625rem;
    color: #fff;
    font-size: 0.9375rem;
    background: linear-gradient(to right, #fc7134, #f54024);
  }
}
</style>